// assets
import { ChromeOutlined, QuestionOutlined, FieldTimeOutlined, FormOutlined, DashboardOutlined, FileTextOutlined } from '@ant-design/icons'

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  FieldTimeOutlined,
  FormOutlined,
  DashboardOutlined,
  FileTextOutlined,
}

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const analyze = {
  id: 'analyze',
  title: 'Analyze',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard/default',
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
    // {
    //   id: 'reports',
    //   title: 'Reports',
    //   type: 'item',
    //   url: '/reports',
    //   icon: icons.FileTextOutlined,
    // },
  ],
}

export default analyze
