import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import MainLayout from 'layout/MainLayout'
import { RouteObject } from 'react-router'

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')))

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')))
const Timesheet = Loadable(lazy(() => import('../pages/tracking/timesheet')))
const Timetracker = Loadable(lazy(() => import('../pages/tracking/timetracker')))

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')))
const Color = Loadable(lazy(() => import('pages/components-overview/Color')))
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')))
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')))

// Analyze pages
const Dashboard = Loadable(lazy(() => import('pages/analyze/dashboard')))
const Reports = Loadable(lazy(() => import('pages/analyze/reports')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: RouteObject = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Dashboard />,
    },
    {
      path: 'color',
      element: <Color />,
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: 'sample-page',
      element: <SamplePage />,
    },
    {
      path: 'reports',
      element: <Reports />,
    },
    {
      path: 'timesheet',
      element: <Timesheet />,
    },
    {
      path: 'timetracker',
      element: <Timetracker />,
    },
    {
      path: 'shadow',
      element: <Shadow />,
    },
    {
      path: 'typography',
      element: <Typography />,
    },
    {
      path: 'icons/ant',
      element: <AntIcons />,
    },
    {
      path: 'dashboardDefault',
      element: <DashboardDefault />,
    },
  ],
}

export default MainRoutes
