import { FunctionComponent } from 'react'
import { TextField, TextFieldProps, InputProps } from '@mui/material'
import { DateInputProps } from './interface'
import DateMaskInput from './dateMaskInput'

const DateInput: FunctionComponent<DateInputProps & TextFieldProps> = ({
  size = 'small',
  onChangeDate,
  onBlurDate,
  value,
  disabled,
  ...props
}) => {
  const onBlurInput = ({ target: { value } }) => {
    onBlurDate && onBlurDate(value)
  }
  return (
    <TextField
      id="dateInput"
      onBlur={onBlurInput}
      variant="standard"
      size={size}
      value={value}
      disabled={disabled}
      onChange={onChangeDate}
      InputProps={
        {
          inputComponent: DateMaskInput,
          disableUnderline: true,
        } as unknown as Partial<InputProps>
      }
      {...props}
    />
  )
}
export default DateInput
