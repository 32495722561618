export const formatTime = (value: string) => {
  if (value.length === 2) {
    return timeProcess(`${value}00`, 2)
  }
  if (value.length === 1) {
    return timeProcess(`${value}00`, 1)
  }
  if (value.length === 3) {
    return timeProcess(`${value}00`, 3)
  }
  return value
}

const timeProcess = (time: string, indexSeparator: number) => {
  const timeFormatted = time.split('').reduce((acumulator, item) => {
    if (acumulator.length === indexSeparator) {
      return `${acumulator}:${item}`
    }
    return acumulator + item
  }, '')
  const [hours, minutes] = timeFormatted.split(':')
  if (indexSeparator === 0) {
    return `00${timeFormatted}`
  }
  if (hours && !minutes) {
    return hours?.length === 1 ? `0${hours}:00` : `${hours}:00`
  }
  return hours?.length === 1 ? `0${hours}:${minutes}` : `${hours}:${minutes}`
}
