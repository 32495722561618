export const getParams = obj => {
  return Object.keys(obj)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    })
    .join('&')
}

export const isWeekend = date => {
  return date.getDay() === 6 || date.getDay() === 0
}
