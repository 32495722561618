import { FunctionComponent, useRef, useState } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { TimeInputProps } from './interface'
import { formatTime } from 'utils/time/formatTime'

const Input: FunctionComponent<TimeInputProps & TextFieldProps> = ({
  onChangeTime,
  error,
  helperText,
  onBlurTime,
  value,
  disabled,
  size = 'small',
  InputProps = {},
  name,
  ...props
}) => {
  const [isDeleting, setDeleting] = useState(false)
  const ref = useRef(null)

  const onBlurInput = ({ target: { value: inputValue } }) => {
    onBlurTime && onBlurTime(formatTime(inputValue))
  }

  const handleChange = evt => {
    const {
      target: { value },
    } = evt
    // Verificamos que el formato sea válido (HH:mm)
    const regex = /^([0-1]?[0-9]|2[0-3])(:[0-5]?[0-9]?)?$/
    if (isDeleting) {
      return onChangeTime(evt)
    }
    if (regex.test(value)) {
      // Agregamos el ":" después de ingresar las horas (HH)
      if (value.length === 2 && !value.includes(':')) {
        onChangeTime({ target: { name, value: value + ':' } })
        return
      }
      onChangeTime(evt)
      return
    }
  }
  const onKeyDown = ({ key }) => {
    // Permitimos las teclas de borrado (backspace o delete) sin validar el formato
    if (key !== 'Enter' && key !== 'Backspace' && key !== 'Delete') {
      setDeleting(false)
      return
    }
    if (key === 'Backspace' || key === 'Delete') {
      setDeleting(() => {
        return true
      })
      return
    }

    ref.current.blur()
  }
  return (
    <TextField
      inputRef={ref}
      onBlur={onBlurInput}
      variant="outlined"
      size={size}
      value={value}
      disabled={disabled}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      error={error}
      helperText={helperText}
      {...props}
      sx={{
        '& .MuiInputBase-root.Mui-disabled': {
          '& > fieldset': {
            borderColor: 'gray.400',
          },
        },
      }}
    />
  )
}
export default Input
