// project import
import Profile from './Profile'
import Notification from './Notification'
import { Box } from '@mui/material'

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  return (
    <>
      <Box sx={{ width: '100%', ml: { xs: 1 } }} />
      <Notification />
      <Profile />
    </>
  )
}

export default HeaderContent
