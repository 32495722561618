// assets
import { ChromeOutlined, QuestionOutlined, FieldTimeOutlined, FormOutlined } from '@ant-design/icons'

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  FieldTimeOutlined,
  FormOutlined,
}

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const tracker = {
  id: 'tracker',
  title: 'Tracking',
  type: 'group',
  children: [
    {
      id: 'timesheet',
      title: 'Timesheet',
      type: 'item',
      url: '/timesheet',
      icon: icons.FormOutlined,
    },
    {
      id: 'time-tracker',
      title: 'Time Tracker',
      type: 'item',
      url: '/timetracker',
      icon: icons.FieldTimeOutlined,
    },
  ],
}

export default tracker
