// project import
import tracking from './tracker'
// import manage from './manage'
import analyze from './analyze'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [tracking, analyze],
}

export default menuItems
