import Routes from './routes'
import ThemeCustomization from './themes'
import ScrollTop from 'components/atoms/ScrollTop'
import { SnackbarProvider } from 'notistack'
import { useGetProfileQuery } from 'redux/services/api/apiSlice'
import MinimalLayout from 'layout/MinimalLayout'

// ==============================|| APP - THEME, ROUTER-NAVIGATION, LOCAL   ||============================== //

const API_URL = process.env['REACT_APP_API_URL']

const App = () => {
  const { data, isLoading, isError, error } = useGetProfileQuery()

  if (isLoading) {
    // TODO: add loading component
    return <MinimalLayout />
  }

  if (isError) {
    // If status is ok means that the user is logged in but the profile is not found
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (error.originalStatus === 200) {
      return <MinimalLayout />
    }
    // Redirect if profile not found, meaning not auth yet
    window.location.replace(`${API_URL}/api/login?returnUrl=${window.location.href}`)
    return null
  }

  if (data) {
    return (
      <ThemeCustomization>
        <ScrollTop>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} autoHideDuration={2000}>
            <Routes />
          </SnackbarProvider>
        </ScrollTop>
      </ThemeCustomization>
    )
  }
}

export default App
