import Navigation from './Navigation'
import SimpleBar from 'components/third-party/SimpleBar'

// ==============================|| DRAWER MENU CONTENT ||============================== //

const DrawerContent = () => (
  <SimpleBar
    sx={{
      '& .simplebar-content': {
        display: 'flex',
        flexDirection: 'column',
      },
    }}
  >
    <Navigation />
    {/* <NavCard /> */}
  </SimpleBar>
)

export default DrawerContent
