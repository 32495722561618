import { FunctionComponent } from 'react'

import MenuItem from '@mui/material/MenuItem'
import { MenuOptionProps } from './interface'

const MenuOption: FunctionComponent<MenuOptionProps> = ({ label, onClick, ...props }) => {
  return (
    <MenuItem {...props} onClick={onClick}>
      {label}
    </MenuItem>
  )
}

export default MenuOption
