import PropTypes from 'prop-types'

// material-ui
import { Avatar, Box, Stack, Typography } from '@mui/material'

import { useGetProfileQuery } from 'redux/services/api/apiSlice'
import { getGravatarUrl } from 'utils/cryptography'

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const { data } = useGetProfileQuery()
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      {data && (
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Avatar alt="profile user" src={getGravatarUrl(data.email || '')} sx={{ width: 32, height: 32 }} />
          <Typography variant="subtitle1">{`${data.firstName} ${data.lastName}`}</Typography>
        </Stack>
      )}
    </Box>
  )
}

export default Profile
