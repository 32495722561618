import * as React from 'react'
import { IMaskInput } from 'react-imask'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const DateMaskInput = React.forwardRef<HTMLElement, CustomProps>(function DateMaskInput(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      mask={'00/00/0000'}
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref as React.RefObject<HTMLInputElement>}
      onAccept={(value: string) => {
        onChange({ target: { name: props.name, value } })
      }}
      overwrite
    />
  )
})

export default DateMaskInput
