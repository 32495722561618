import { FunctionComponent, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ScrollTopProps } from './interface'

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

export const ScrollTop: FunctionComponent<ScrollTopProps> = ({ children }) => {
  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return children || null
}

export default ScrollTop
