import PropTypes from 'prop-types'
import { useMemo } from 'react'

// material-ui
import { Box } from '@mui/material'

// project import
import DrawerHeader from './DrawerHeader'
import DrawerContent from './DrawerContent'
import MiniDrawerStyled from './MiniDrawerStyled'

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open }) => {
  // header content
  const drawerContent = useMemo(() => <DrawerContent />, [])
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open])

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label="mailbox folders">
      <MiniDrawerStyled variant="permanent" open={open}>
        {drawerHeader}
        {drawerContent}
      </MiniDrawerStyled>
    </Box>
  )
}

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object,
}

export default MainDrawer
